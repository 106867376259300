import { SystemProps, Flex, Box, Icon, css, Text } from "@storyofams/react-ui";
import { Heading } from "../../Heading";
import {
  USPRabbit,
  USPLeaves,
  USPLeaf,
  USPRecycling,
  USPTree,
  USPEarth,
  USPGlobe,
  USPGlutenFree,
  USPParabenFree,
  USPSpray,
  USPCareProducts,
  USPCream,
  Hours24,
  Vegan,
  SodaFree,
  TenMin,
  Personalisations,
  DIYKit,
  UurHydratatie,
  MinLip,
  VeganLippen,
  DIYKitLip,
  ParaffineVrij,
  PersLip,
  DiepeHydratatie,
  MinGemaakt,
  LabInEenDoos,
  MultiPurpose,
  VeganNew,
  ParaffineVrijNew,
  DuurzaamheidBombshell,
  DuurzaamheidFreshbalance,
  DuurzaamheidMixset,
  DuurzaamheidNightngiht,
  DuurzaamheidTropicalzest,
  KleiBombshell,
  KleiFreshbalance,
  KleiMixset,
  KleiNightnight,
  KleiTropicalzest,
  NatuurlijkBombshell,
  NatuurlijkFreshbalance,
  NatuurlijkMixset,
  NatuurlijkNightnight,
  NatuurlijkTropicalzest,
  VeganDIYBathbomb,
  MinDIYbb,
  LabindoosDIYbb,
} from "../../Icons";
import { RichText } from "../../RichText";

type UspBarProps = {
  first?: boolean;
  block?: boolean;
  content: {
    usp?: {
      content?: {
        bg_enabled?: boolean;
        bg_color?: {
          color: string;
        };
        border_color?: {
          color: string;
        };
        padding?: number;
        text_color?: {
          color: string;
        };
        title?: string;
        usps?: {
          _uid?: any;
          title?: string;
          icon?: any;
          text?: any;
        }[];
      };
    };
    anchor_id?: string;
  };
} & SystemProps;

const getIcon = (icon) => {
  switch (icon) {
    case "leaves":
      return USPLeaves;
    case "leaf":
      return USPLeaf;
    case "recycling":
      return USPRecycling;
    case "tree":
      return USPTree;
    case "earth":
      return USPEarth;
    case "globe":
      return USPGlobe;
    case "gluten-free":
      return USPGlutenFree;
    case "paraben-free":
      return USPParabenFree;
    case "spray":
      return USPSpray;
    case "care-products":
      return USPCareProducts;
    case "cream":
      return USPCream;
    case "hours24":
      return Hours24;
    case "vegan":
      return Vegan;
    case "soda-free":
      return SodaFree;
    case "min10":
      return TenMin;
    case "personalisation":
      return Personalisations;
    case "diy-kit":
      return DIYKit;
    case "3-uur-hydratatie":
      return UurHydratatie;
    case "10-min-lip":
      return MinLip;
    case "100-vegan-lippen":
      return VeganLippen;
    case "diy-kit-lip":
      return DIYKitLip;
    case "paraffine-vrij":
      return ParaffineVrij;
    case "personalise-lip":
      return PersLip;
    case "diepe-hydratatie":
      return DiepeHydratatie;
    case "in-10-min-gemaakt":
      return MinGemaakt;
    case "lab-in-een-doos":
      return LabInEenDoos;
    case "multi-purpose":
      return MultiPurpose;
    case "vegan-new":
      return VeganNew;
    case "paraffine-vrij-new":
      return ParaffineVrijNew;
    case "natuurlijk-freshbalance":
      return NatuurlijkFreshbalance;
    case "natuurlijk-tropicalzest":
      return NatuurlijkTropicalzest;
    case "natuurlijk-bombshell":
      return NatuurlijkBombshell;
    case "natuurlijk-mixset":
      return NatuurlijkMixset;
    case "natuurlijk-nightnight":
      return NatuurlijkNightnight;
    case "duurzaamheid-bombshell":
      return DuurzaamheidBombshell;
    case "duurzaamheid-freshbalance":
      return DuurzaamheidFreshbalance;
    case "duurzaamheid-mixset":
      return DuurzaamheidMixset;
    case "duurzaamheid-nightnight":
      return DuurzaamheidNightngiht;
    case "duurzaamheid-tropicalzest":
      return DuurzaamheidTropicalzest;
    case "klei-bombshell":
      return KleiBombshell;
    case "klei-tropicalzest":
      return KleiTropicalzest;
    case "klei-freshbalance":
      return KleiFreshbalance;
    case "klei-mixset":
      return KleiMixset;
    case "klei-nightnight":
      return KleiNightnight;
    case "vegan-diybathbomb":
      return VeganDIYBathbomb;
    case "labindoos-diybb":
      return LabindoosDIYbb;
    case "min10-diybb":
      return MinDIYbb;
    default:
      return USPRabbit;
  }
};

export const UspBar = ({ content, first, block, ...props }: UspBarProps) => {
  return (
    <Box
      px={["10px", "30px"]}
      bg={
        content?.usp?.content?.bg_enabled
          ? content.usp.content.bg_color.color
          : "inherit"
      }
      textColor={
        content?.usp?.content?.text_color
          ? content.usp.content.text_color.color
          : "inherit"
      }
      border={
        content?.usp?.content?.bg_enabled
          ? `1px solid ${content?.usp?.content?.border_color?.color}`
          : "none"
      }
      borderRadius={content?.usp?.content?.bg_enabled ? "md" : "none"}
      id={content?.anchor_id}
      textAlign="center"
      // px={{ md: "15" }}
      css={{
        width: "100%",
        ".swiper-slide": {
          width: "266.66px",
        },
        ".swiper-button-next, .swiper-button-prev": {
          display: "none",
        },
      }}
      {...props}
    >
      {!!content?.usp?.content?.title && (
        <Heading
          maxWidth={400}
          as={first ? "h1" : "h2"}
          variant="h3"
          fontWeight="medium"
          mb={[4, 5]}
        >
          {content?.usp?.content?.title}
        </Heading>
      )}
      <Flex
        // mt={[2, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
        alignItems={"stretch"}
      >
        {content?.usp?.content?.usps?.map(({ _uid, title, text, icon }, i) => (
          <Box
            textAlign="center"
            key={_uid}
            mx={[2, 1]}
            mt={[i !== 0 ? 3 : 0, 0]}
          >
            <Icon
              height={[50, 100]}
              width={[50, 100]}
              mt="20px"
              css={css({ svg: { width: "100%", height: "100%" } })}
              icon={getIcon(icon)}
            />
            <Text
              lineHeight={["24px", "36px"]}
              fontSize={["18px", "24px"]}
              fontWeight="600"
              mt={["5px", "12px"]}
            >
              {title}
            </Text>
            <RichText
              mb="10px"
              fontSize={"16px"}
              lineHeight={"24px"}
              text={text}
              mt={"5px"}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
